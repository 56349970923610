import { useEnv } from '../providers/EnvContext';

export const useStoreToken = () => {
    const { setIdToken, setAccessToken } = useEnv();

    const storeToken = (data) => {
        setIdToken(data.id_token);
        setAccessToken(data.access_token);

        sessionStorage.setItem('tai_idt', data.id_token);
        sessionStorage.setItem('tai_act', data.access_token);
    }

    return storeToken;
}

export const useReuseToken = () => {
    const { setIdToken, setAccessToken } = useEnv();

    const reuseToken = () => {
        setIdToken(sessionStorage.getItem('tai_idt'));
        setAccessToken(sessionStorage.getItem('tai_act'));
    }

    return reuseToken;
}