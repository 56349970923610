import React from 'react';

// Mainly for development purposes

const Reset = () => {
    sessionStorage.clear();
    localStorage.clear();

    return (
        <div className="px-8 py-4">
            <div className="py-4">Reset done.</div>
            <div onClick={() => { window.location.href = '/'; }} className="text-cta-blue cursor-pointer inline-block">Start over</div>
        </div>
    );
};

export default Reset;