import { storeOrigUrl } from "./storeOrigUrl";
import { useEnv } from '../providers/EnvContext';

export const useReinitAuth = () => {
    const { setReInitSessionMsg } = useEnv();

    const reInitAuth = () => {
        // Stop automatic retry flow after 3 attempts
        let currRetryCount = parseInt(sessionStorage.getItem('reInitRetryCount'), 10) || 0;
        currRetryCount += 1;
        sessionStorage.setItem('reInitRetryCount', currRetryCount);
        const noRetry = currRetryCount >= 4;
        storeOrigUrl();
        sessionStorage.removeItem('tai_idt');
        sessionStorage.removeItem('tai_act');
        if (noRetry) {
            sessionStorage.removeItem('reInitRetryCount');
            setReInitSessionMsg('SOMETHING_WENT_WRONG_PLEASE_REFRESH');
        } else {
            setReInitSessionMsg('SESSION_EXPIRED_REFRESHING');
            // Show the reinit session msg for a few seconds before redirecting
            setTimeout(() => {
                window.location.href = `${window.location.origin}/login`;
            }, 3000);
        }
    }
    return reInitAuth;
}

export const useResolveIfInReinitFlow = () => {
    const resolveIfInReinitFlow = () => {
        if (sessionStorage.getItem('reInitRetryCount')) {
            sessionStorage.removeItem('reInitRetryCount');
        }
    }
    return resolveIfInReinitFlow;
}