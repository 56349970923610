import { useEnv } from '../providers/EnvContext';
import { useFetchExtend } from '../utils/fetchExtend';

export const useGetUsers = () => {
    const { idToken, apiBaseUrl } = useEnv();
    const fetchExtend = useFetchExtend();

    const getUsers = async () => {
        if (!idToken) return;
        const apiUrl = `${apiBaseUrl}/users`;

        const headers = {
            'Authorization': idToken,
            'Content-Type': 'application/json'
        };

        try {
            const response = await fetchExtend(apiUrl, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const responseData = await response.json();
                return responseData;
            } else if (response.status === 401) {
                return { error: 'unauthorized' };
            } else {
                throw new Error(`Request failed with status: ${response.status}`);
            }
        } catch (e) {
            console.error('Error:', e);
            return { error: 'Failed to get users' };
        }
    }

    return getUsers;
}

export const useAddOrEditUser = () => {
    const { idToken, apiBaseUrl } = useEnv();
    const fetchExtend = useFetchExtend();

    const addOrEditUser = async (userObj) => {
        if (!idToken) return;
        const apiUrl = `${apiBaseUrl}/users`;

        const headers = {
            'Authorization': idToken,
            'Content-Type': 'application/json'
        };

        try {
            const response = await fetchExtend(apiUrl, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(userObj)
            });
            if (response.ok) {
                return 201;
            } else {
                throw new Error(`Request failed with status: ${response.status}`);
            }
        } catch (e) {
            console.error('Error:', e);
            return { error: 'Failed to add or edit user' };
        }
    }

    return addOrEditUser;
}

export const useDeleteUser = () => {
    const { idToken, apiBaseUrl } = useEnv();
    const fetchExtend = useFetchExtend();

    const deleteUser = async (email) => {
        if (!idToken) return;
        const apiUrl = `${apiBaseUrl}/users?key=${encodeURIComponent(email)}`;

        const headers = {
            'Authorization': idToken,
            'Content-Type': 'application/json'
        };

        try {
            const response = await fetchExtend(apiUrl, {
                method: 'DELETE',
                headers: headers,
            });
            if (response.ok) {
                return 201;
            } else {
                throw new Error(`Request failed with status: ${response.status}`);
            }
        } catch (e) {
            console.error('Error:', e);
            return { error: 'Failed to delete user' };
        }
    }

    return deleteUser;
}