import { useReinitAuth } from "./reInitAuth";

// Extends fetch with a login redirect in case of a 403 (authorizer block)
export const useFetchExtend = () => {
    const reInitAuth = useReinitAuth();

    const fetchExtend = async (url, options) => {
        const response = await fetch(url, options);
        if (response.status === 403) reInitAuth();
        return response;
    }
    return fetchExtend;
}