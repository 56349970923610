import React, { createContext, useState, useContext, useEffect } from 'react';
import CircleLoader from '../components/CircleLoader';

const EnvContext = createContext();

const API_BASE_URL = process.env.NODE_ENV !== 'production' // all local dev
    ? (process.env.REACT_APP_DEV_API_BASE_URL_OVERRIDE || process.env.REACT_APP_DEV_API_BASE_URL)
    : `https://api.${window.location.hostname}`;


export const EnvProvider = ({ children }) => {
    const [idToken, setIdToken] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [username, setUsername] = useState('');
    const [uid, setUid] = useState('');
    const [initReady, setInitReady] = useState(false);
    const [reInitSessionMsg, setReInitSessionMsg] = useState(null);
    const [detailsRenderReady, setDetailsRenderReady] = useState(false);

    useEffect(() => {
        if (sessionStorage) {
            // All configuration must exist, otherwise redirect to login
            const idt = sessionStorage.getItem('tai_idt');
            const act = sessionStorage.getItem('tai_act');
            if (idt && act) {
                setIdToken(idt);
                setAccessToken(act);
            }
        }
        setInitReady(true);
    }, []);

    if (!initReady) {
        return <div><CircleLoader /></div>;
    }

    return (
        <EnvContext.Provider value={{
            idToken,
            accessToken,
            username,
            uid,
            reInitSessionMsg,
            detailsRenderReady,
            setIdToken,
            setAccessToken,
            setUsername,
            setUid,
            setReInitSessionMsg,
            setDetailsRenderReady,
            apiBaseUrl: API_BASE_URL
        }}>
            {children}
        </EnvContext.Provider>
    );
};

export const useEnv = () => {
    const context = useContext(EnvContext);
    if (context === undefined) {
        throw new Error('useEnv must be used within an EnvProvider');
    }
    return context;
};
