export const useStoreEnvconfig = () => {
    const storeEnvconfig = (data) => {
        sessionStorage.setItem('tai_gtmid', data.gtmId || '');
        sessionStorage.setItem('tai_survicateWorkspace', data.survicateWorkspace);
        sessionStorage.setItem('tai_traceUrl', data.traceUrl || '');
        sessionStorage.setItem('tai_driveFaults', data.driveFaults);
    }
    return storeEnvconfig;
}

export const useSetTechnicianFlag = () => {
    const setTechnicianFlag = (val) => {
        sessionStorage.setItem('tai_technician', val);
    }
    return setTechnicianFlag;
}