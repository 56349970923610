import React from 'react';

const KoneLoader = () => {
    return (
        <>
            <style>
                {`.kone-loading-holder {
                    background: #fff;/*page bg color*/
                    padding-top: calc(100vh - 50px);
                    width: 100%;
                    position: relative;
                    margin-top: -$navbar-h;
                    @include breakpoint(xs) {
                    margin-top: -$navbar-h-mobile;
                    }
                }
                .kone-loading-holder svg { /*svg sizes, layouts*/
                    height: 96px; 
                    width: 96px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    }
                    .kone-loading-holder #k {
                    fill: #0071B9; 
                    animation: drop1 2.5s cubic-bezier(0,.64,.69,1) 0s infinite normal;}
                    .kone-loading-holder #o {
                    fill: #0071B9; 
                    animation: rise1 2.5s cubic-bezier(0,.64,.69,1) 0s infinite normal;}
                    .kone-loading-holder #n {
                    fill: #0071B9; 
                    animation: drop2 2.5s cubic-bezier(0,.64,.69,1) 0s infinite normal;}
                    .kone-loading-holder #e {
                    fill: #0071B9; 
                    animation: rise2 2.5s cubic-bezier(0,.64,.69,1) 0s infinite normal;}
                
                @keyframes drop1 {
                    0%   {opacity: 0;transform: translateY(-36px);}
                    20%   {opacity: 0;transform: translateY(-36px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
                    54%  {transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
                    65% {opacity: 1;}
                    70% {}
                    100% {}
                }
                @keyframes rise1 {
                    0%   {opacity: 0;transform: translateY(36px);}
                    27%  {opacity: 0;transform: translateY(36px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
                    55%  {transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
                    70% {opacity: 1;}
                    100% {}
                }
                @keyframes drop2 {
                    0%   {opacity: 0;transform: translateY(-36px);}
                    34%  {opacity: 0;transform: translateY(-36px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
                    58%  {transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
                    60% {}
                    75% {opacity: 1;}
                    100% {}
                }
                @keyframes rise2 {
                    0%   {opacity: 0;transform: translateY(36px);}
                    40%  {opacity: 0;transform: translateY(36px);-webkit-filter: url('#blur1'); filter: url('#blur1');}
                    60% {transform: translateY(0);-webkit-filter: url('#blur2'); filter: url('#blur2');}
                    80% {opacity: 1;}
                    100% {}
                }`}
            </style>
            <div className="kone-loading-holder">

                <svg width="96" height="96" viewBox="0 0 96 96" preserveAspectRatio="xMidYMid meet"
                    xmlns="http://www.w3.org/2000/svg">
                    <path id="e" d="M72.989,23.973v48.059h21.676V23.973H72.989z M90.664,43.113h-8.841v3.031h7.767l-0.002,3.839
	h-7.765v3.04h8.841v4.069H77.099V39.16h13.565V43.113z" />
                    <path id="n" d="M49.153,23.973v48.059h21.673V23.973H49.153z M67.965,57.091h-2.418l-9.105-9.097v9.097H52.02
	v-17.93h2.736l8.772,8.756v-8.756h4.437V57.091z" />
                    <path id="o" d="M25.327,23.973v48.059h21.673V23.973H25.327z M36.163,57.363
	c-5.152,0-9.297-3.812-9.297-9.363c0-5.552,4.145-9.356,9.297-9.356c5.154,0,9.299,3.804,9.299,9.356
	C45.461,53.551,41.316,57.363,36.163,57.363z M40.678,48c0,3.043-1.975,5.131-4.516,5.131c-2.547,0-4.516-2.088-4.516-5.131
	c0-3.036,1.968-5.131,4.516-5.131C38.703,42.869,40.678,44.964,40.678,48z" />
                    <path id="k" d="M1.489,23.973v48.059h21.674V23.973H1.489z M15.534,57.091l-6.146-8.495v8.495H4.607v-17.93
	h4.781v7.823l5.918-7.823h5.631l-6.535,8.44l6.983,9.49H15.534z" />

                    <filter id="blur1">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="0 3" />
                    </filter>
                    <filter id="blur2">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="0 0" />
                    </filter>
                </svg>
            </div>
        </>
    );
};

export default KoneLoader;

