import React from 'react';

const Avatar = ({ actorType, userLetter, size }) => {
  const avtSize = size === 'sm' ? '8' : '10';
  const textSize = size === 'sm' ? 'text-xl' : 'text-2xl';
  const commonClasses = `h-${avtSize} w-${avtSize} rounded-full flex items-center justify-center ${textSize} text-white bg-slate-700`;

  return (
    <div className={commonClasses}>
      {actorType === 'ai' ? (
        <img
          src="/images/ai-avt.svg"
          alt="ai-avt"
          className="absolute inset-0 m-auto h-6 w-6 fill-white"
        />
      ) : null}
      {actorType === 'you' ? (
        <span className="text-bold">{userLetter}</span>
      ) : null}
    </div>
  );
};

export default Avatar;
