export const storeOrigUrl = () => {
    const url = new URL(window.location.href);
    const path = url.pathname;
    const searchParams = url.search;
    const testVal = path + searchParams;

    // Match the patterns
    // Pattern 1: '/chat/something' where something is alphanumeric, hyphens, or underscores, with an optional 'u' parameter
    const p1 = /^\/chat\/[a-zA-Z0-9-_]+(?:\?u=[a-zA-Z0-9-_]+)?$/;
    // Pattern 2: '/chat?ken={ken}' with optional multiple UTM parameters and an optional 'lang' param
    // Allowed UTM parameters: utm_source, utm_medium, utm_campaign
    const p2 = /^\/chat\?ken=\d+(&utm_(source|medium|campaign)=[^&]+)*(?:&lang=[a-zA-Z-_]+)?$/;
    // Pattern 3: '/admin/users'
    const p3 = /^\/admin\/users$/;

    if (p1.test(testVal) || p2.test(testVal) || p3.test(testVal)) {
        sessionStorage.setItem('origUrl', path + searchParams);
    }
};

export const removeOrigUrl = () => {
    sessionStorage.removeItem('origUrl');
};