import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const DEFAULT_LANG = 'en-US';

/**
 * Explicitly setting one of available languages, used in both
 * i18n initial lang detection and setting active language.
 */
function mapLanguage(lang) {
  const langCode = lang.toLowerCase().substring(0, 2);
  switch (langCode) {
    case 'fr':
      return 'fr';
    case 'it':
      return 'it';
    case 'fi':
      return 'fi';
    case 'zh':
      return 'zh-CN';
    case 'ms':
      return 'ms-MY';
    default:
      return DEFAULT_LANG;
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LANG,
    debug: process.env.NODE_ENV !== 'production', // disabled in live envs
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator']
    },
    preprocess: mapLanguage
  }).then(() => {
    /**
     * mapLanguage call needed again, since the above init hook will only
     * set the selected language phrases (i.e. 'fr-CA' will show 'fr' phrases),
     * but it will _not_ set the active language, which is used to determine
     * the selected item in the language selection list in the app.
     */
    const mappedLang = mapLanguage(i18n.language);
    i18n.changeLanguage(mappedLang);
  });

export default i18n;
