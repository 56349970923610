import React, { useState, useEffect, useRef } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { useDeleteUser, useGetUsers, useAddOrEditUser } from '../services/userAdminApi';
import GlobalFilterEmail from '../components/GlobalFilterEmail';
import KoneLoader from '../components/KoneLoader';

const UserAdmin = () => {
    const [users, setUsers] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [updatedData, setUpdatedData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [authError, setAuthError] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const usersReqRef = useRef(false);

    const getUsers = useGetUsers();
    const addOrEditUser = useAddOrEditUser();
    const deleteUser = useDeleteUser();

    const sortUsers = () => {
        setUsers((prevUsers) => {
            return [...prevUsers].sort((a, b) =>
                a.email_address.localeCompare(b.email_address)
            );
        });
    };

    const initiate = async () => {
        if (usersReqRef.current) return;
        usersReqRef.current = true;
        const usersRes = await getUsers();
        if (usersRes.error === 'unauthorized') {
            setAuthError(true);
        } else {
            document.title = 'Users';
            document.body.classList.add('beta');
            setUsers(usersRes.data);
            sortUsers();
        }
        setIsLoading(false);
    };

    useEffect(() => {
        initiate();
    }, []); // eslint-disable-line

    const handleDelete = async (email) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete ${email}? This action cannot be undone.`);
        if (isConfirmed) {
            try {
                await deleteUser(email);
                console.log(`Deleted user: ${email}`);
                setUsers((prevUsers) => {
                    const updatedUsers = prevUsers.filter(user => user.email_address !== email);
                    return updatedUsers;
                });
                sortUsers();

            } catch (error) {
                console.error('Failed to delete user:', error);
            }
        }
    };

    const handleEditClick = (index, user) => {
        setEditIndex(index);
        setUpdatedData(user);
        setIsAddingUser(false);
    };

    const handleSaveClick = async () => {
        if (!updatedData.email_address) return;

        const userDataObj = {
            email_address: updatedData.email_address.toLowerCase(),
            is_pilot_technician: updatedData.is_pilot_technician,
            country: updatedData.country
        };

        try {
            await addOrEditUser(userDataObj);

            if (editIndex !== null) {
                setUsers((prevUsers) => {
                    const updatedUsers = [...prevUsers];
                    updatedUsers[editIndex] = userDataObj;
                    return updatedUsers;
                });
            } else {
                setUsers((prevUsers) => [userDataObj, ...prevUsers]);
            }
        } catch (e) {
            console.error(e);
        }
        setIsAddingUser(false);
        setEditIndex(null);
    };

    const handleCancelClick = () => {
        setEditIndex(null);
        setIsAddingUser(false);
    };

    const handleAddUserClick = () => {
        setIsAddingUser(true);
        setUpdatedData({
            email_address: '',
            is_pilot_technician: '',
            country: ''
        });
        setEditIndex(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedData({ ...updatedData, [name]: value });
    };

    const columns = React.useMemo(() => [
        {
            Header: 'Email',
            accessor: 'email_address',
        },
        {
            Header: 'Technician',
            accessor: 'is_pilot_technician',
        },
        {
            Header: 'Country',
            accessor: 'country',
        },
        {
            Header: '',
            accessor: 'action',
            Cell: ({ row }) => (
                <div className="flex justify-end">
                    {editIndex === row.index ? (
                        <>
                            <button
                                onClick={handleSaveClick}
                                className="bg-cta-blue text-white px-4 py-2 rounded-full font-bold hover:opacity-90"
                            >
                                Save
                            </button>
                            <button
                                onClick={handleCancelClick}
                                className="bg-gray-200 text-gray-500 px-4 py-2 rounded-full font-bold hover:opacity-90 ml-2"
                            >
                                Cancel
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={() => handleEditClick(row.index, row.original)}
                                className="bg-theme-light-blue text-cta-blue border border-blue-400 font-bold px-4 py-2 rounded-full hover:opacity-90"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDelete(row.original.email_address)}
                                className="bg-rose-100 text-red-500 font-bold px-4 py-2 rounded-full ml-2 hover:opacity-90"
                            >
                                Delete
                            </button>
                        </>
                    )}
                </div>
            ),
        },
    ], [editIndex, updatedData]); // eslint-disable-line

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: users,
            initialState: { pageIndex: currentPageIndex, pageSize: 20 },
        },
        useGlobalFilter,
        usePagination
    );

    useEffect(() => {
        setCurrentPageIndex(pageIndex);
    }, [pageIndex]);

    if (isLoading) {
        return <div><KoneLoader /></div>
    }

    if (authError) {
        return <div className="p-8">Unauthorized</div>
    }

    return (
        <main className="min-h-screen bg-white p-8 w-full">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-3xl mb-10 font-bold">Users</h1>
                <div className="flex justify-between mb-4">
                    <GlobalFilterEmail globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                    <button
                        onClick={handleAddUserClick}
                        className="bg-cta-blue text-white font-bold px-12 py-2 rounded-full hover:opacity-90"
                    >
                        + Add User
                    </button>
                </div>
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 mt-4">
                    <thead className="bg-theme-light-blue border-t border-gray-200">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {isAddingUser && (
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="text"
                                        value={updatedData.email_address}
                                        name="email_address"
                                        placeholder="Enter email"
                                        onChange={handleInputChange}
                                        className="border border-gray-300 px-4 py-2 w-full rounded-full"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="text"
                                        value={updatedData.is_pilot_technician}
                                        name="is_pilot_technician"
                                        placeholder="Technician"
                                        onChange={handleInputChange}
                                        className="border border-gray-300 px-4 py-2 w-full rounded-full"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="text"
                                        value={updatedData.country}
                                        name="country"
                                        placeholder="Country"
                                        onChange={handleInputChange}
                                        className="border border-gray-300 px-4 py-2 w-full rounded-full"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right">
                                    <button
                                        onClick={handleSaveClick}
                                        className="bg-cta-blue text-white px-4 py-2 rounded-full font-bold hover:opacity-90"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={handleCancelClick}
                                        className="bg-gray-200 text-gray-500 px-4 py-2 rounded-full font-bold hover:opacity-90 ml-2"
                                    >
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        )}
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            {...cell.getCellProps()}
                                            className="px-6 py-4 whitespace-nowrap text-gray-500"
                                        >
                                            {editIndex === row.index && cell.column.id === 'email_address' ? (
                                                <input
                                                    type="text"
                                                    value={updatedData[cell.column.id]}
                                                    name={cell.column.id}
                                                    className="border border-gray-300 px-4 py-2 rounded-full w-full"
                                                    disabled
                                                />
                                            ) : editIndex === row.index && cell.column.id !== 'action' ? (
                                                <input
                                                    type="text"
                                                    value={updatedData[cell.column.id]}
                                                    name={cell.column.id}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-300 px-4 py-2 rounded-full w-full"
                                                />
                                            ) : (
                                                cell.render('Cell')
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="pagination py-4 flex justify-between items-center border-t border-gray-200 mt-4">
                    <button
                        onClick={() => previousPage()}
                        className={`${!canPreviousPage ? 'opacity-50 pointer-events-none' : 'cursor-pointer'} px-4 py-2 bg-theme-light-blue rounded-full font-bold`}
                    >
                        Previous
                    </button>
                    <span className="text-gray-800">
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </span>
                    <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className={`${!canNextPage ? 'opacity-50 pointer-events-none' : 'cursor-pointer'} px-4 py-2 bg-theme-light-blue rounded-full font-bold`}
                    >
                        Next
                    </button>
                </div>
            </div>
        </main>
    );
};

export default UserAdmin;
