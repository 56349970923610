import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { EnvProvider } from './providers/EnvContext';
import AppRouting from './AppRouting';

const App = () => {
  return (
    <EnvProvider>
      <Router>
        <Routes>
          <Route path="*" element={<AppRouting />} />
        </Routes>
      </Router>
    </EnvProvider>
  );
};

export default App;
