import React from 'react';

const origin = window.location.origin;
let envPrefix = 'dev.';
if (origin === 'https://qa.assistant.kone.com') {
  envPrefix = 'qa2.';
} else if (origin === 'https://assistant.kone.com') {
  envPrefix = '';
}
const loginUrl = `https://api.${envPrefix}base.iotp.kone.com/authmgmt/authorization/login?redirectUri=${origin}/login-callback`

const Login = () => {
  window.location.href = loginUrl;

  return (
    <div></div>
  );
};

export default Login;