import { useEnv } from '../providers/EnvContext';
import { useTranslation } from 'react-i18next';
import { useFetchExtend } from '../utils/fetchExtend';

export const useGetUserInfo = () => {
    const { idToken, apiBaseUrl } = useEnv();
    const fetchExtend = useFetchExtend();

    const getUserInfo = async () => {
        if (!idToken) return;
        const apiUrl = `${apiBaseUrl}/user-info`;

        const headers = {
            'Authorization': idToken,
            'Content-Type': 'application/json'
        };

        try {
            const response = await fetchExtend(apiUrl, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const responseData = await response.json();
                return responseData;
            } else {
                throw new Error(`Request failed with status: ${response.status}`);
            }
        } catch (e) {
            console.error('Error:', e);
            /*  No explicit handling.
                Forbidden will redirect to login automatically (via fetchExtend interceptor).
                Otherwise userinfo (name) will simply not be set. */
        }
    }

    return getUserInfo;
}

export const useGetUserChats = () => {
    const { t } = useTranslation('translate');
    const { idToken, apiBaseUrl } = useEnv();
    const fetchExtend = useFetchExtend();

    const getUserChats = async (fromChatId) => {
        const params = fromChatId ? `?from_chat_id=${fromChatId}` : '';
        if (!idToken) return;
        const apiUrl = `${apiBaseUrl}/user-chats${params}`;

        const headers = {
            'Authorization': idToken,
            'Content-Type': 'application/json'
        };

        try {
            const response = await fetchExtend(apiUrl, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const responseData = await response.json();
                return responseData;
            } else {
                throw new Error(`Request failed with status: ${response.status}`);
            }
        } catch (e) {
            console.error('Error:', e);
            return { error: t('ERRORS.AN_ERROR_OCCURRED_WHILE_LOADING_CHAT_HISTORY') };
        }
    }

    return getUserChats;
}