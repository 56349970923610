import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

const GlobalFilterEmail = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 300);

    return (
        <span>
            <input
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Search by email"
                className="border px-4 py-2 rounded-full"
            />
        </span>
    );
};

export default GlobalFilterEmail;