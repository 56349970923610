import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStoreToken } from './services/tokenHandler';
import { useStoreEnvconfig, useSetTechnicianFlag } from './services/envconfig';
import { useEnv } from './providers/EnvContext';
import KoneLoader from './components/KoneLoader';
import Avatar from './components/Avatar';

function LoginCallback() {
    const location = useLocation();
    const navigate = useNavigate();
    const codeExchangedRef = useRef(false);
    const { apiBaseUrl } = useEnv();
    const [loginFailed, setLoginFailed] = useState(false);
    const [userNotAuthorized, setUserNotAuthorized] = useState(false);

    const storeToken = useStoreToken();
    const storeEnvconfig = useStoreEnvconfig();
    const setTechnicianFlag = useSetTechnicianFlag();

    const codeExchange = async (code) => {
        if (codeExchangedRef.current) return;
        codeExchangedRef.current = true;

        const headers = {
            'Content-Type': 'application/json'
        };

        const data = { code, redirectUri: `${origin}/login-callback` };

        try {
            const response = await fetch(`${apiBaseUrl}/auth/token`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const responseData = await response.json();
                storeEnvconfig(responseData.envconfig);
                setTechnicianFlag(responseData.pilot_technician || false);
                storeToken(responseData);
            } else if (response.status === 401) {
                setUserNotAuthorized(true);
            } else {
                setLoginFailed(true);
            }
        } catch (e) {
            console.error('Error:', e);
            setLoginFailed(true);
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');

        if (code && !codeExchangedRef.current) {
            codeExchange(code);
        } else if (!code) {
            setTimeout(() => {
                navigate('/login');
            }, 500);
        }
    }, [location]); // eslint-disable-line

    return (
        <>
            {userNotAuthorized ? (
                <div className="h-screen w-full flex flex-col items-center justify-center bg-theme-light-blue px-12 pb-16 text-center">
                    <div className="relative"><Avatar actorType="ai" /></div>
                    <div className="pt-6 text-base text-gray-500">Patience, a new service is coming!<br />You are not authorized to log in yet.</div>
                </div>
            ) : loginFailed ? (
                <div className="h-screen w-full flex flex-col items-center justify-center bg-theme-light-blue px-12 pb-16 text-center">
                    <div className="relative"><Avatar actorType="ai" /></div>
                    <div className="pt-6 text-base text-gray-500">Login expired. Try again by clicking the button below.</div>
                    <div
                        className="mt-8 cursor-pointer text-center font-bold bg-cta-blue text-white h-10 py-2 px-6 rounded-full inline-block"
                        data-te="true"
                        data-tc="Login"
                        data-ta="Refresh login flow"
                        onClick={() => navigate('/login')}
                    >
                        Log in
                        <img
                            src="/assets/icons/chevron-right.svg"
                            alt="Log in"
                            className="h-5 inline fill-white -mt-0.5 ml-2 -mr-2"
                        />
                    </div>
                </div>
            ) : (
                <div><KoneLoader /></div>
            )
            }
        </>
    );
}

export default LoginCallback;